import React, {Component} from 'react';

class SectionAutore extends Component {
    render() {
        return (
            <section id="author" className="ftco-section testimony-section img ftco-animate" style={this.props.autoreStileSfondo}>
                <div className="overlay"></div>
                <div className="container">
                    <div className="row d-md-flex justify-content-center">
                        <div className="col-md-8 heading-section text-left ftco-animate">
							<div className="item">
								<div className="testimony-wrap text-center">
									<div className="user-img mb-5" style={this.props.autoreStile}>
										<span className="quote d-flex align-items-center justify-content-center">
											<i className="icon-quote-left"></i>
										</span> 
									</div>
									<div className="text">
										<p className="name">{this.props.autoreNome}</p>
										<span className="position">{this.props.autoreTitle}</span>
										<p dangerouslySetInnerHTML={{ __html: this.props.autoreBiografia }}></p>
									</div>
								</div>
							</div>
                        </div>
                    </div>
				</div>
            </section>
        );
    }
}
export default SectionAutore;