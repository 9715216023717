import React, {Component} from 'react';

class SectionEbook extends Component {
    render() {
        return (
            <section id="ebook" className="ftco-section ftco-about-section">
                <div className="container-fluid pl-5">
                    <div className="row d-md-flex text-wrapper ftco-animate">
                        <div className="one-half img" style={this.props.ebookStile}></div>
                        <div className="one-half half-text d-flex justify-content-end align-items-center">
                            <div className="text-inner pl-md-5">
                                <h3 className="heading">{this.props.ebookTitle}</h3>
                                <p>{this.props.ebookTagLine}</p>
                                <ul className="my-4" dangerouslySetInnerHTML={{ __html: this.props.ebookElenco }}></ul>
                                {this.props.ebookInfoAccessibilita} <a href={this.props.ebookLinkAccessibilita} title={this.props.ebookTitleLink} target="_blank" rel="noreferrer noopener">{this.props.ebookDescAccessibilita}</a>.
                                <p>{this.props.ebookCallAction}</p>

                                {this.props.ebookLink ? (
                                    <>
                                        <p><a href={this.props.ebookLink} className="btn btn-info py-3 px-4" target="_blank" rel="noreferrer noopener">{this.props.ebookButton}</a></p>
                                    </>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
export default SectionEbook;