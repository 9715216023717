import React, {Component} from 'react';
import Intestazione from './components_novel/intestazione';
import Piedone from './components/piedone';
import Navbar from './components/navbar';

import logo from './images/Logo_Eliosfera-trasparente-scritta_nera-orizzontale-due-righe-v.3.1-70x280.png';

import intestazioneBackgroundImage from './images/verso-hemelslinn-sfondo-2.jpg';


const intestazioneBackgroundStyle = {backgroundImage: `url(${intestazioneBackgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center center', backgroundAttachment: 'fixed', height: '300px'};

// Create the function
export function AddLibrary(urlOfTheLibrary) {
	const script = document.createElement('script');
	script.src = urlOfTheLibrary;
	script.async = true;
	document.body.appendChild(script);
}


class Privacy extends Component {
	state = {
		aNavbarLinks: [
			{id: 0, linkHref: "/", linkNome: "HOME"},
		],
	}
	render() {
		return (
			<>
				<div className="page">
					{/*SEZIONE BARRA DI NAVIGAZIONE */}
					<Navbar 
						linkSito='https://www.eliosfera.it'
						logo={logo}
						deskLogo='Il logo della casa editrice Eliosfera'
						aNavbarLinks = {this.state.aNavbarLinks}
					/>
					<div id="pagina">
						<Intestazione
							pagina="INFORMATIVA SULLA PRIVACY"
							backgroundStyle={intestazioneBackgroundStyle}
							titolo="VERSO HEMELSLINN"
							romanzo="un romanzo di"
							autore="MAURIZIO FERUGLIO"
						/>


						<section className="ftco-section bg-dark">
							<div className="container">
								<div className="row justify-content-center">
									<div className="col-md-8 mb-5 heading-section text-center ftco-animate">
										<div className="heading-section text-center mb-5">
											<h2>PRIVACY</h2>
											<p>Politica sulla Privacy</p>
										</div>
									</div>
								</div>
								<div className="row d-flex">
								<p>In Verso Hemelslinn, accessibile da https://romanzo.towardhemelslinn.io, una delle nostre priorità principali è la privacy dei nostri visitatori. Il presente documento di Informativa sulla privacy contiene i tipi di informazioni raccolte e registrate da Eliosfera Editrice e il modo in cui le utilizziamo.</p>
								<p>Se hai ulteriori domande o richiedi maggiori informazioni sulla nostra Informativa sulla privacy, non esitare a contattarci.</p>
								<p>La presente Informativa sulla privacy si applica solo alle nostre attività online ed è valida per i visitatori del nostro sito Web per quanto riguarda le informazioni che hanno condiviso e/o raccolto su Verso Hemelslinn. Questa politica non è applicabile ad alcuna informazione raccolta offline o tramite canali diversi da questo sito web. La nostra Informativa sulla privacy è stata creata con l'aiuto del <a href="https://www.privacypolicygenerator.info" target="_blank" rel="noreferrer noopener">Generatore gratuito di Informativa sulla privacy</a>.</p>

								<h2>Consenso</h2>
								<p>Utilizzando il nostro sito Web, acconsenti alla nostra Informativa sulla privacy e ne accetti i termini.</p>

								<h2>Informazioni che raccogliamo</h2>
								<p>Le informazioni personali che ti vengono richieste e i motivi per cui ti viene chiesto di fornirle ti verranno chiariti nel momento in cui ti chiederemo di fornire le tue informazioni personali.</p>
								<p>Se ci contatti direttamente, potremmo ricevere ulteriori informazioni su di te come nome, indirizzo email, numero di telefono, contenuto del messaggio e/o degli allegati che potresti inviarci e qualsiasi altra informazione che potresti scegliere di fornire.</p>
								<p>Quando registri un account, potremmo chiederti le informazioni di contatto, inclusi elementi quali nome, nome dell'azienda, indirizzo, indirizzo email e numero di telefono.</p>

								<h2>Come utilizziamo le tue informazioni</h2>
								<p>Utilizziamo le informazioni che raccogliamo in vari modi, tra cui:</p>
								<ul>
									<li>Fornire, gestire e mantenere il nostro sito web.</li>
									<li>Migliorare, personalizzare ed espandere il nostro sito web.</li>
									<li>Comprendere e analizzare come utilizzi il nostro sito web.</li>
									<li>Sviluppare nuovi prodotti, servizi, caratteristiche e funzionalità.</li>
									<li>Comunicare con te, direttamente o tramite uno dei nostri partner, anche per il servizio clienti, per fornirti aggiornamenti e altre informazioni relative al sito web e per scopi di marketing e promozionali.</li>
									<li>Inviarti email.</li>
									<li>Individuare e prevenire le frodi.</li>
								</ul>

								<h2>File di registro</h2>
								<p>Eliosfera Editrice segue una procedura standard di utilizzo dei file di registro. Questi file registrano i visitatori quando visitano i siti Web. Tutte le società di hosting fanno questo e una parte dell'analisi dei servizi di hosting. Le informazioni raccolte dai file di registro includono indirizzi IP (protocollo Internet), tipo di browser, provider di servizi Internet (ISP), data e ora, pagine di riferimento/uscita ed eventualmente il numero di clic. Questi non sono collegati ad alcuna informazione che possa identificare la persona. Lo scopo delle informazioni è analizzare le tendenze, amministrare il sito, monitorare il movimento degli utenti sul sito Web e raccogliere informazioni demografiche.</p>

								<h2>Politiche sulla privacy dei partner pubblicitari</h2>
								<p>È possibile consultare questo elenco per trovare l'Informativa sulla privacy per ciascuno dei partner pubblicitari di Verso Hemelslinn.</p>
								<p>Gli AD server o le reti pubblicitarie di terze parti utilizzano tecnologie come cookie, JavaScript o Web Beacon utilizzati nei rispettivi annunci pubblicitari e collegamenti visualizzati su Verso Hemelslinn, che vengono inviati direttamente al browser degli utenti. Ricevono automaticamente il tuo indirizzo IP quando ciò accade. Queste tecnologie vengono utilizzate per misurare l'efficacia delle loro campagne pubblicitarie e/o per personalizzare il contenuto pubblicitario che vedi sui siti web che visiti.</p>
								<p>Tieni presente che Verso Hemelslinn non ha accesso o controllo su questi cookie utilizzati da inserzionisti di terze parti.</p>

								<h2>Politiche sulla privacy di terze parti</h2>
								<p>La politica sulla privacy di Verso Hemelslinn non si applica ad altri inserzionisti o siti web. Pertanto, ti consigliamo di consultare le rispettive Politiche sulla privacy di questi ad server di terze parti per informazioni più dettagliate. Potrebbe includere le loro pratiche e istruzioni su come rinunciare a determinate opzioni. </p>
								<p>Puoi scegliere di disabilitare i cookie attraverso le opzioni del tuo browser individuale. Per conoscere informazioni più dettagliate sulla gestione dei cookie con browser web specifici, è possibile trovarle sui rispettivi siti web dei browser.</p>

								<h2>Diritti sulla privacy CCPA (non vendere le mie informazioni personali)</h2>
								<p>Secondo il CCPA, tra gli altri diritti, i consumatori hanno il diritto di:</p>
								<p>Richiedere che un'azienda che raccoglie i dati personali di un consumatore divulghi le categorie e i dati personali specifici che un'azienda ha raccolto sui consumatori.</p>
								<p>Richiedere a un'azienda di eliminare tutti i dati personali sul consumatore raccolti da un'azienda.</p>
								</div>
							</div>
						</section>

           
						<Piedone
							piedoneLinkHowToMint=''
							piedoneLinkNFTsProvenance=''
							piedoneLinkEbooksProvenance=''
							piedoneLinkWhitePaper=''
							piedoneLinkTermConditions=''
							piedoneLinkCookies='/cookies'
							piedoneLinkPrivacy='/privacy'
							piedoneLinkSmartContracts=''
							piedoneIframe=''
							piedoneTwitter='https://twitter.com/eliosferaed'
							piedoneInstagram=''
							piedoneFacebook='https://www.facebook.com/eliosfera'
							piedoneDiscord=''
							piedonePinterest=''
							piedoneOpensea=''
							piedoneEliosfera='hhttps://www.eliosfera.it/maurizio-feruglio-verso-hemelslinn-copertina-rigida-libro'
							piedoneYoutube='https://www.youtube.com/@eliosferaeditrice2911'
							piedoneCopyright="Eliosfera Editrice &copy;"
							piedoneCopyrightDisclaimer="Tutti i diritti riservati"
							piedoneCCBY='Questo template è costruito sulla base di <i class="icon-heart" aria-hidden="true"></i> <a href="https://colorlib.com" target="_blank">Colorlib</a>'
						/>




      				</div>
					{AddLibrary('js/jquery.min.js')}
					{AddLibrary('js/jquery-migrate-3.0.1.min.js')}
					{AddLibrary('js/jquery.waypoints.min.js')}
					{AddLibrary('js/jquery.stellar.min.js')}
					{AddLibrary('js/owl.carousel.min.js')}
					{AddLibrary('js/jquery.magnific-popup.min.js')}
					{AddLibrary('js/aos.js')}
					{AddLibrary('js/jquery.animateNumber.min.js')}
					{AddLibrary('js/scrollax.min.js')}
					{AddLibrary('js/jquery.mb.YTPlayer.min.js')}
					{AddLibrary('js/bootstrap-datepicker.js')}
					{AddLibrary('js/jquery.timepicker.min.js')}
					{AddLibrary('js/main.js')}

    			</div>
			
			</>
		);
	}
}

export default Privacy;