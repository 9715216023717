import React, {Component} from 'react';

class Post extends Component {
    render() {
		return (
			<div className="col-md-4 d-flex ftco-animate">
				<div className="blog-entry bg-dark align-self-stretch">
					<a className="block-20" title={this.props.deskLinkBlog} href={this.props.linkPost} style={this.props.stilePost} target="_blank" rel="noreferrer noopener" >&nbsp;</a>
					<div className="text p-4 d-block">
						<div className="meta mb-3">
							<div>{this.props.dataPost}</div>
							<div>{this.props.autorePost}</div>
						</div>
						<h3 className="heading mt-3"><a target="_blank" rel="noreferrer noopener" title={this.props.deskLinkBlog} href={this.props.linkPost}>{this.props.titoloPost}</a></h3>
						<p dangerouslySetInnerHTML={{ __html: this.props.descrizionePost }}></p>
						<p><a target="_blank" rel="noreferrer noopener" title={this.props.deskLinkBlog} href={this.props.linkPost} className="btn btn-info">{this.props.buttonText}</a></p>
					</div>
				</div>
			</div>
        );
    }
}

export default Post;