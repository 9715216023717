import React, {Component} from 'react';

class SectionAnteprima  extends Component {
    render() {
        return (
            <section id="preview" className="ftco-appointment ftco-section">
                <div className="overlay"></div>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8 mb-5 heading-section text-center ftco-animate">
                            <h2 className="mb-4">{this.props.anteprimaTitolo}</h2>
                            <p dangerouslySetInnerHTML={{ __html: this.props.anteprimaTagLine }}></p>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-8 appointment ftco-animate">
                            <form className="appointment-form" action={this.props.anteprimaAction} method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" target="_blank" >
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="d-md-flex">
                                            <div className="form-group">
                                                <input className="form-control" type="text" name="FNAME" id="mce-FNAME" placeholder={this.props.anteprimaNomePlaceholder} required />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="d-me-flex">
                                            <div className="form-group">
                                                <input type="email" className="form-control" name="EMAIL" id="mce-EMAIL" placeholder={this.props.anteprimaEmailPlaceholder} required />
                                                <input type="hidden" value="html" name="EMAILTYPE" id="mce-EMAILTYPE-0" defaultChecked="checked" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <strong>{this.props.anteprimaPrivacy}</strong><br />
                                            <input className="av-checkbox" type="checkbox" id="gdpr_17501" name="gdpr[17501]" value="Y" required />
                                            <label htmlFor="mce-group[17361]-17361-0">&nbsp;{this.props.anteprimaConsenso}</label>
                                            &nbsp;<a href="/privacy" target="_blank">{this.props.anteprimaInformativaPrivacy}</a>.	
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <div id="mce-error-response" style={{display: "none"}}></div>
                                            <div id="mce-success-response" style={{display: "none"}}></div>
                                            <div style={{position: "absolute", left: "-5000px"}} aria-hidden="true">
                                                <input type="text" name="b_81158fe55b1b7e6f79918d667_25ffa18bb3" tabIndex="-1" />
                                            </div>
                                            <input type="submit" value={this.props.anteprimaButton} name="subscribe" id="mc-embedded-subscribe" className="btn btn-info py-3 px-4" />  
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
export default SectionAnteprima;