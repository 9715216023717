import React, {Component} from 'react';

class SectionQuarta extends Component {
    render() {
        return (
            <section id="quarta" className="ftco-section bg-dark ftco-about-section">
                <div className="container-fluid px-5 ftco-animate">
                    <div className="row d-md-flex text-wrapper">
                        <div className="one-half" style={this.props.stileQuarta}></div>
                        <div className="one-half half-text d-flex justify-content-end align-items-center">
                            <div className="text-inner pl-md-5">
                                <h3 className="heading">{this.props.titoloQuarta}</h3>
                                <div className="CookieDeclaration" dir="ltr" lang="it" dangerouslySetInnerHTML={{ __html: this.props.descrizioneQuarta }}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
export default SectionQuarta;