import React, {Component} from 'react';

import Book from './components/book';
import VideoBackground from './components/videobackground';
import Capitolo from './components/capitolo';
import Quarta from './components_novel/quarta';
import Anteprima from './components/anteprima';
import Ebook from './components/ebook';
import Autore from './components/autore';
import Estratto from './components/estratto';
import Estratto2 from './components/estratto2';
//import Contattaci from './components/contattaci';
import BooktrailerModal from './components/booktrailermodal'
import Piedone from './components/piedone';
import Navbar from './components/navbar';

import logo from './images/Logo_Eliosfera-trasparente-scritta_nera-orizzontale-due-righe-v.3.1-70x280.png';
import videoBackgroundImage from './images/verso-hemelslinn-sfondo-video-2.jpg';
import backgroundEstrattoImage1 from './images/Astronauta-deriva.jpg';
import backgroundBookImage1 from './images/verso-hemelslinn-volume.jpg';
import backgroundBookImage2 from './images/verso-hemelslinn-copertina.jpg'; 
import backgroundBookImage3 from './images/Verso-Hemelslinn-Frontespizio.jpg';
import backgroundBookImage4 from './images/verso-hemelslinn-ebook.jpg';
import backgroundCapitoloImage1 from './images/sommario/verso-hemelslinn-preludio.jpg';
import backgroundCapitoloImage2 from './images/sommario/verso-hemelslinn-prixian.jpg';
import backgroundCapitoloImage3 from './images/sommario/verso-hemelslinn-in-viaggio-per-il-futuro.jpg';
import backgroundCapitoloImage4 from './images/sommario/verso-hemelslinn-inseguimento.jpg';
import backgroundCapitoloImage5 from './images/sommario/verso-hemelslinn-uno-strano-incontro.jpg';
import backgroundCapitoloImage6 from './images/sommario/verso-hemelslinn-lisier.jpg';
import backgroundCapitoloImage7 from './images/sommario/verso-hemelslinn-naufraghi.jpg';
import backgroundCapitoloImage8 from './images/sommario/verso-hemelslinn-primo-contatto.jpg';
import backgroundCapitoloImage9 from './images/sommario/verso-hemelslinn-il-continente-di-ghiaccio.jpg';
import backgroundCapitoloImage10 from './images/sommario/verso-hemelslinn-la-battaglia-sulla-neve.jpg';
import backgroundPostImage1 from './images/blog/Banner-184-Sirius-book-375x300.jpg';
import backgroundPostImage2 from './images/blog/Banner-171-Nel-crepaccio-book-375x300.jpg';
import backgroundQuartaImage from './images/Banner-verso-hemelslinn-book-fronte-retro.png';
import backgroundEbookImage from './images/Banner-verso-hemelslinn-tablet-smartphone.png';
import backgroundAutoreSfondoImage from './images/verso-hemelslinn-stella-plasma.jpg';
import backgroundAutoreImage from './images/Maurizio-Feruglio.jpg';
import backgroundEstrattoImage2 from './images/il-dilemma-del-gorilla-03.jpg';
//import backgroundEstrattoImage3 from './images/join-our-discord.jpg';

import booktrailerModalImage from './images/Banner-Youtube-Verso-Hemelslinn-booktrailer-3.1-960x540.jpg';
import videoBackgroundLocal from './images/verso-hemelslinn-background-v.3.1.mp4';


const videoBackgroundStyle = {backgroundImage: `url(${videoBackgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center center', backgroundAttachment: 'fixed', height: '700px'};
const styleBook1 = {backgroundImage: `url(${backgroundBookImage1})`,};
const styleBook2 = {backgroundImage: `url(${backgroundBookImage2})`,};
const styleBook3 = {backgroundImage: `url(${backgroundBookImage3})`,};
const styleBook4 = {backgroundImage: `url(${backgroundBookImage4})`,};
const styleCapitolo1 = {backgroundImage: `url(${backgroundCapitoloImage1})`,};
const styleCapitolo2 = {backgroundImage: `url(${backgroundCapitoloImage2})`,};
const styleCapitolo3 = {backgroundImage: `url(${backgroundCapitoloImage3})`,};
const styleCapitolo4 = {backgroundImage: `url(${backgroundCapitoloImage4})`,}; 
const styleCapitolo5 = {backgroundImage: `url(${backgroundCapitoloImage5})`,};
const styleCapitolo6 = {backgroundImage: `url(${backgroundCapitoloImage6})`,};
const styleCapitolo7 = {backgroundImage: `url(${backgroundCapitoloImage7})`,};
const styleCapitolo8 = {backgroundImage: `url(${backgroundCapitoloImage8})`,};
const styleCapitolo9 = {backgroundImage: `url(${backgroundCapitoloImage9})`,};
const styleCapitolo10 = {backgroundImage: `url(${backgroundCapitoloImage10})`,};
const PostStyle1 = {backgroundImage: `url(${backgroundPostImage1})`,};
const PostStyle2 = {backgroundImage: `url(${backgroundPostImage2})`,};
const sectionQuartaStyle = {backgroundImage: `url(${backgroundQuartaImage})`,};
const sectionEbookStyle = {backgroundImage: `url(${backgroundEbookImage})`,};
const sectionAutoreSfondoStyle = {backgroundImage: `url(${backgroundAutoreSfondoImage})`,};
const sectionAutoreStyle = {backgroundImage: `url(${backgroundAutoreImage})`,};
const EstrattoStyle1 = {backgroundImage: `url(${backgroundEstrattoImage1})`,};
const EstrattoStyle2 = {backgroundImage: `url(${backgroundEstrattoImage2})`,};
//const EstrattoStyle3 = {backgroundImage: `url(${backgroundEstrattoImage3})`,};

// Funzione per aggiungere dei javascript esterni
export function AddLibrary(urlOfTheLibrary) {
	const script = document.createElement('script');
	script.src = urlOfTheLibrary;
	script.async = true;
	document.body.appendChild(script);
}


class App extends Component {
	state = {
		aBooks: [
			{id: 0, bookTitolo: "Volume", bookStile: styleBook1, bookDescrizione: '<p>Formati: <span>6"x9" - 15,2x22,8cm</span></p><p>Rilegature: <span>copertina rigida laminata</span><br/>oppure <span>copertina flessibile</span> <span>(paperback)</span></p><p>Tipo: <span>fresato</span></p>'},
			{id: 1, bookTitolo: "Copertina", bookStile: styleBook2, bookDescrizione: "<p>Colori: <span>quadricromia</span></p><p>Carta: <span>rivestita lucida</span></p><p>Spessore: <span>cartone pressato 1.600g/m<sup>2</sup></span><br/>oppure <span>cartoncino 300g/m<sup>2</sup></span></p><p>Laminazione: <span>lucida</span></p>"},
			{id: 2, bookTitolo: "Pagine", bookStile: styleBook3, bookDescrizione: "<p>Numero: <span>418</span></p><p>Carta: <span>avorio, uso mano</span></p><p>Spessore: <span>90g/m<sup>2</sup></span></p>"},
			{id: 3, bookTitolo: "EBook", bookStile: styleBook4, bookDescrizione: "<p>Formati: <span>Epub, Kindle, Mobi, PDF</span></p><p>Protezione: <span>nessuna</span></p><p>Accessibilità: <span>accessibile</span></p><p>Dimensione: <span>1.626 KB</span></p>"}
		],
		aCapitolo: [
			{id: 0, capitoloStile: styleCapitolo1, capitoloTitolo: "Preludio", capitoloDescrizione: "L'incipit. L'esplosione di una stella, il fulcro della storia."},
		{id: 1, capitoloStile: styleCapitolo2, capitoloTitolo: "Prixian", capitoloDescrizione: "Alla scoperta dell'ambiente, della cultura e della società in cui vive l'equipaggio della Delphís."},
		{id: 2, capitoloStile: styleCapitolo3, capitoloTitolo: "In viaggio per il futuro", capitoloDescrizione: "L'inizio del viaggio. Fuga dagli inseguitori, nuovi incontri e arrivo alla prima destinazione."},
		{id: 3, capitoloStile: styleCapitolo4, capitoloTitolo: "L'inseguimento", capitoloDescrizione: "L'equipaggio della Delphìs, braccato, deve rifornirsi al più presto."},
		{id: 4, capitoloStile: styleCapitolo5, capitoloTitolo: "Uno strano incontro", capitoloDescrizione: "Un protagonista entra in gioco aiutandoli a fuggire."},
		{id: 5, capitoloStile: styleCapitolo6, capitoloTitolo: "Lisier", capitoloDescrizione: " faccia a faccia con una stella, in corsa contro il tempo."},
		{id: 6, capitoloStile: styleCapitolo7, capitoloTitolo: "Naufraghi", capitoloDescrizione: "La quiete e la fulgida luce dominavano il mare di tranquillità. Un vascello vi navigava senza meta."},
		{id: 7, capitoloStile: styleCapitolo8, capitoloTitolo: "Primo contatto", capitoloDescrizione: "L'equipaggio della Delphìs atterra su un pianeta sconosciuto."},
		{id: 8, capitoloStile: styleCapitolo9, capitoloTitolo: "Il continente di ghiaccio", capitoloDescrizione: "Viaggio nella terra dei ghiacci."},
		{id: 9, capitoloStile: styleCapitolo10, capitoloTitolo: "La battaglia sulla neve", capitoloDescrizione: "La battaglia che accenderà i sentimenti."}
		],
		aBlog: [
			{id: 0, stilePost: PostStyle1, titoloPost: "Sirius", deskLinkBlog: "Link all'anteprima di Sirius", dataPost: "08 febbraio 2024", autorePost: "Eliosfera Editrice", linkPost: "https://www.eliosfera.it/blog-sirius-anteprima-verso-hemelslinn-feruglio", descrizionePost: "Oggi vogliamo farvi leggere, in anteprima, la pagina con il tema <strong>Sirius</strong>."},
			{id: 1, stilePost: PostStyle2, titoloPost: "Crepaccio", deskLinkBlog: "Link all'anteprima di Crepaccio", dataPost: "05 october 2021", autorePost: "Eliosfera Editrice", linkPost: "https://www.eliosfera.it/blog-crepaccio-anteprima-verso-hemelslinn-feruglio", descrizionePost: "Oggi vogliamo farvi leggere un'anteprima della pagina con il tema <strong>Crepaccio</strong>."},
		],
		aNavbarLinks: [
			{id: 2, linkHref: "/#summary", linkNome: "SOMMARIO"},
			{id: 3, linkHref: "/#booktrailer", linkNome: "BOOKTRAILER"},
			{id: 4, linkHref: "/#preview", linkNome: "ANTEPRIMA"},
			{id: 5, linkHref: "/#ebook", linkNome: "EBOOK"},
			{id: 6, linkHref: "/#author", linkNome: "AUTORE"},
		],
	}


	render() {
		return (
			<>
				<div className="page">
					<Navbar 
						linkSito='https://www.eliosfera.it'
						logo={logo}
						deskLogo='Il logo della casa editrice Eliosfera'
						aNavbarLinks = {this.state.aNavbarLinks}
					/>
					<div id="pagina">

						{/* SECTION VIDEOBACKGROUND */}
						<VideoBackground 
							backgroundStyle={videoBackgroundStyle}
							backgroundVideo={videoBackgroundLocal}
							titolo="VERSO<br />HEMELSLINN"
							romanzo="un romanzo di"
							autore="MAURIZIO FERUGLIO"
							tagLine="Un'emozionante avventura, un amore oltre il tempo."
							tagLine2="Il romanzo è disponibile su Amazon nei formati ebook, copertina rigida e copertina flessibile."
							buttonPrenota="ACQUISTA"
							buttonLink='https://amzn.to/43biilu'
						/>


						{/* SECTION BOOK */}
						<section id="book" className="ftco-section ftco-no-pt ftco-featured-model">
							<div className="container-fluid">
								<div className="row">
									{this.state.aBooks.map(sectionbook => (
										<Book
											key={sectionbook.id}
											sectionbook={sectionbook}
										/>
									))}
								</div>
							</div>
						</section>


						{/* SECTION ESTRATTO */}
						<Estratto
							estrattoId="beyond"
							estrattoStile={EstrattoStyle1}
							estrattoTitolo="Amore oltre il tempo"
							estrattoContenuto="Il buzzer iniziò a suonare, sul visore apparve l’alert rosso. Il casco che sembrava integro, in realtà aveva una microcrepa alla giuntura della visiera. Il messaggio che ricevette era che se fosse uscita in attività extraveicolare, in poco più di trenta secondi si sarebbe spaccato in due.
							Se lo sganciò e, in un impeto di rabbia, lo scaraventò sulla parete, ottenendo solo di sbattere contro la parete opposta. Diede un pugno allo sportello dello scomparto tute. In quel momento il dolore fisico era corroborante, meno intenso del dolore emotivo. Si avvicinò al portello esterno e guardò attraverso l’oblò. Khàleb era a soli quindici metri da lei e non poteva raggiungerlo, non poteva salvarlo! Si appoggio con la fronte al vetro, afferrò la maniglia con la mano sinistra e diede con la destra diversi pugni, come se volesse frantumare la parete vitrea. Pianse."
							estrattoButton="ACQUISTA"
							estrattoButtonLink='https://amzn.to/43biilu'
						/>
						

						{/* SECTION SUMMARY */}
						<section id="summary" className="ftco-section ftco-no-pb">
							<div className="container-fluid px-4">
								<div className="row d-flex">
									<div className="col-md-6 col-lg-3 d-flex align-items-center ftco-animate">
										<div className="heading-section text-center">
											<h2 className="">SOMMARIO</h2>
											<p>Scorri i capitoli di Verso Hemelslinn e scopri l'avventura!</p>
										</div>
									</div>
									{this.state.aCapitolo.map(sectioncapitolo => (
										<Capitolo
										key={sectioncapitolo.id}
										sectioncapitolo={sectioncapitolo} />
									))}
									<div className="col-md-6 col-lg-3 d-flex justify-content-center align-items-center ftco-animate">
										<div className="btn-view">
											<p><a href="/summary" className="btn btn-info py-3 px-5">Guarda tutti i capitoli</a></p>
										</div>
									</div>
								</div>
							</div>
						</section>




					    {/* SECTION BLOG */}
						{/*<section id="blog" className="ftco-section">
							<div className="container">
								<div className="row justify-content-center">
									<div className="col-md-8 mb-5 heading-section text-center ftco-animate">
										<h2 className="mb-4">Blog</h2>
										<p className="mb-5">News, articoli ed eventi</p>
									</div>
								</div>
								<div className="row d-flex">
									{this.state.aBlog.map(blogpost => (
										<Post
											key={blogpost.id}
											stilePost={blogpost.stilePost}
											titoloPost={blogpost.titoloPost}
											deskLinkBlog={blogpost.deskLinkBlog}
											dataPost={blogpost.dataPost}
											autorePost={blogpost.autorePost}
											linkPost={blogpost.linkPost}
											buttonText="Read more…"
											descrizionePost={blogpost.descrizionePost}
										/>
									))}
									<div className="col-md-6 col-lg-3 d-flex justify-content-center align-items-center ftco-animate">
										<div className="btn-view">
											<p><a href="/blog" className="btn btn-info py-3 px-5">Leggi tutti gli articoli</a></p>
										</div>
									</div>
								</div>
							</div>
						</section>*/}	
						

						{/* SECTION QUARTA DI COPERTINA */}				
						<Quarta 
							stileQuarta={sectionQuartaStyle}
							titoloQuarta="La storia"
							descrizioneQuarta="
							<p>In un lontano futuro l’umanità è sparsa su milioni di pianeti sotto l’ègida di Galaxias. I viaggi interplanetari sono appannaggio di una piccola élite di gilde galattiche che monopolizzano le risorse minerarie necessarie per la navigazione interstellare. L’umanità, relegata sui pianeti colonizzati, vive un periodo di oscurantismo. Il pericolo del dominio dispotico e delle restrizione della libertà è sempre più palpabile.</p>
							<p>Per sventare l’imminente colpo di stato delle gilde, l’equipaggio della Delphís progetta la rivoluzione che libererà l’umanità dal giogo degli oppressori. Una rivoluzione pacifica che permetterà i viaggi spaziali su ampia scala, donando all’umanità la possibilità di esprimere tutto il suo potenziale nelle arti, nelle scienze e nell'esplorazione dell'universo.</p>
							<p>Nel bel mezzo della missione l’equipaggio della Delphís si ritrova alla deriva vicino a un pianeta sconosciuto. L’unica speranza di sopravvivere è atterrarvi. Conosceranno un’antica cultura; chiave del passato, del presente e del futuro.</p>
							<p>Una space opera di ampio respiro. Un amore oltre il tempo. Un’avventura avvincente con spunti di riflessione sulla libertà, l’amicizia, l'ecosostenibilità e la diversità.</p>"
						/>


						{/* SECTION BOOKTRAILER */}
						<BooktrailerModal
							booktrailerModalId="booktrailer"
							booktrailerModalImage={booktrailerModalImage}
							booktrailerModalVideo="QUsz3NGsSHw"
							booktrailerModalTitolo="BOOK TRAILER"
							booktrailerModalTagLine="Guarda il trailer del libro e lasciati coinvolgere dall'avventura!"
							booktrailerModalAltImg="Banner trailer del libro Verso Hemelslinn"
						/>


						{/* SECTION ANTEPRIMA */}
						<Anteprima
							anteprimaTitolo="Anteprima"
							anteprimaTagLine="Se vuoi leggere l'anteprima di <em>Verso Hemelslinn</em>, inserisci il tuo nome e la tua email. Riceverai nella tua casella di posta il link per scaricare l'anteprima in formato PDF (in italiano)."
							anteprimaAction="https://towardhemelslinn.us11.list-manage.com/subscribe?u=81158fe55b1b7e6f79918d667&id=f6b53c0a1e" //Link form Mailchimp
							anteprimaButton="SCARICA L'ANTEPRIMA"
							anteprimaConsenso="Accetto il trattamento dei miei dati personali. "
							anteprimaInformativaPrivacy="Informativa sulla privacy"
							anteprimaPrivacy="Consenso alla privacy"
							anteprimaNomePlaceholder="Nome"
							anteprimaEmailPlaceholder="Indirizzo Email"
						/>


						{/* SECTION EBOOK */}
						<Ebook
							ebookStile={sectionEbookStyle}
							ebookTitle="VERSO HEMELSLINN ebook ePub"
							ebookTagLine="Leggilo anche in ebook su tutti i dispositivi!"
							ebookElenco='<li><span class="ion-ios-checkmark-circle mr-2"></span> <em>Accessibile</em> per non vedenti e ipovedenti, in formato epub3.</li>
							<li><span class="ion-ios-checkmark-circle mr-2"></span> Altri formati disponibili: Kindle, Mobi e PDF.</li>
							<li><span class="ion-ios-checkmark-circle mr-2"></span> Puoi leggerlo con uno screen reader o un lettore braille.</li>
							<li><span class="ion-ios-checkmark-circle mr-2"></span> Puoi leggerlo con Kindle, Kobo e altri eReader.</li>
							<li><span class="ion-ios-checkmark-circle mr-2"></span> Puoi leggerlo su tablet, smartphone o PC.</li>'
							ebookInfoAccessibilita="Per saperne di più sull'accessibilità, leggi (in italiano):"
							ebookLinkAccessibilita="https://www.eliosfera.it/ebook-con-contenuti-accessibili-eliosfera-editrice"
							ebookTitleLink="Link all'articolo Ebook con contenuti accessibili."
							ebookDescAccessibilita="Ebook con contenuti accessibili"
							ebookCallAction="Allora, cosa stai aspettando? L'avventura ti aspetta anche in digitale!"
							ebookLink="https://bit.ly/3upLxps"
							ebookButton="ACQUISTA L'EBOOK"
						/>

						
						{/* SECTION AUTORE */}
						<Autore
							autoreStileSfondo={sectionAutoreSfondoStyle}
							autoreStile={sectionAutoreStyle}
							autoreTitle="L'AUTORE"
							autoreNome="Maurizio Feruglio"
							autoreBiografia="L'autore è il fondatore di una software house. È un responsabile IT, uno sviluppatore di software e un amministratore di sistema.
							Negli ultimi anni ha studiato nuovi linguaggi di programmazione, come Flutter, Node.js React e Solidity (per lo sviluppo di Smart Contracts nella blockchain di Ethereum). Si è anche interessato alle nuove tecnologie Web3 come NFT e DAPP.
							Da sempre appassionato di scienza e fantascienza, apprezza anche i grandi autori classici. La sua passione per la letteratura lo ha portato a scrivere alcuni romanzi e racconti esplorando vari generi narrativi.
							Con Eliosfera Editrice ha pubblicato il romanzo <em>Verso Hemelslinn</em>, la raccolta di racconti <em>LETTERE. Storie in un incipit</em> e il racconto utopistico <em>ESSERE</em>."
						/>


						{/* SECTION ESTRATTO 2 */}
						<Estratto2 
							estrattoId="gorilla"
							estrattoStile={EstrattoStyle2}
							estrattoTitolo="IL DILEMMA DEL GORILLA"
							estrattoContenuto="Un maestoso gorilla lo stava fissando con cipiglio. Le lunghe e possenti braccia erano puntate dritte con i pugni a terra. I severi occhi marroni risaltavano sullo sfondo nero del muso. Era come se gli stesse parlando. Deciditi, mostrati uomo, fai ciò che è giusto. Non puoi tradire i tuoi amici. Fatti aiutare, per amore di Khalìse. Sentiva riecheggiare queste frasi nella mente come se fosse quel fiero animale a infondergliele. Per qualche minuto i loro occhi rimasero fissi, scrutandosi. Infine il gorilla si sedette e il suo sguardo si rasserenò. Provò la stessa sensazione, come se gliel’avesse trasmessa."
							estrattoButton="ACQUISTA"
							estrattoButtonLink='https://amzn.to/43biilu'
						/>


						{/* SECTION CONTATTACI 
						<Contattaci
							contattaciTitolo="CONTATTACI"
							contattaciTagLine="Se hai domande, utilizza questo modulo per inviarci il tuo messaggio"
							contattaciAction="sendemail.php"
							contattaciButton="   INVIA   " 
							contattaciConsensoPrivacy="Consenso alla privacy"
							contattaciConsenso="Acconsento al trattamento dei miei dati personali."
							contattaciPrivacy="Informativa sulla privacy"
							contattaciNomePlaceholder="Nome"
							contattaciEmailPlaceholder="Indirizzo Email"
							contattaciMessaggioPlaceholder="Inserisci qui il tuo mesaggio"
						/> */}


						{/* SECTION ESTRATTO 2 
						<Estratto2
							estrattoId="discord"
							estrattoStile={EstrattoStyle3}
							estrattoTitolo="JOIN OUR COMMUNITY"
							estrattoContenuto="Join the Discord community and become a part of <em>Galaxias</em>. An exciting adventure awaits you!"
							estrattoButton="JOIN OUR DISCORD"
							estrattoButtonLink='https://discord.gg/bWJ9nDthpA'
						/>*/}


						{/* SECTION PIEDONE */}
						<Piedone
							piedoneLinkTermConditions=''
							piedoneLinkCookies='/cookies'
							piedoneLinkPrivacy='/privacy'			
							piedoneIframe=''
							piedoneTwitter='https://twitter.com/eliosferaed'
							piedoneInstagram=''
							piedoneFacebook='https://www.facebook.com/eliosfera'
							piedoneDiscord=''
							piedonePinterest=''
							piedoneOpensea=''
							piedoneEliosfera='https://www.eliosfera.it/maurizio-feruglio-verso-hemelslinn-copertina-rigida-libro'
							piedoneYoutube='https://www.youtube.com/@eliosferaeditrice2911'
							piedoneCopyright="Eliosfera Editrice &copy;"
							piedoneCopyrightDisclaimer="Tutti i diritti riservati"
							piedoneCCBY='Template basato su <i class="icon-heart" aria-hidden="true"></i> <a href="https://colorlib.com" target="_blank">Colorlib</a>'
						/>

					</div>
					
					{AddLibrary('js/jquery.min.js')}
					{AddLibrary('js/jquery-migrate-3.0.1.min.js')}
					{AddLibrary('js/jquery.waypoints.min.js')}
					{AddLibrary('js/jquery.stellar.min.js')}
					{AddLibrary('js/owl.carousel.min.js')}
					{AddLibrary('js/jquery.magnific-popup.min.js')}
					{AddLibrary('js/aos.js')}
					{AddLibrary('js/jquery.animateNumber.min.js')}
					{AddLibrary('js/scrollax.min.js')}
					{AddLibrary('js/jquery.mb.YTPlayer.min.js')}
					{AddLibrary('js/bootstrap-datepicker.js')}
					{AddLibrary('js/jquery.timepicker.min.js')}
					{AddLibrary('js/main.js')}
				</div>
				
			</>
		);
	}
}

export default App;
