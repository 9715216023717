import React, {Component} from 'react';
import Intestazione from './components_novel/intestazione';
import Piedone from './components/piedone';
import Navbar from './components/navbar';

import logo from './images/Logo_Eliosfera-trasparente-scritta_nera-orizzontale-due-righe-v.3.1-70x280.png';

import intestazioneBackgroundImage from './images/verso-hemelslinn-sfondo-2.jpg';


const intestazioneBackgroundStyle = {backgroundImage: `url(${intestazioneBackgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center center', backgroundAttachment: 'fixed', height: '300px'};

// Create the function
export function AddLibrary(urlOfTheLibrary) {
	const script = document.createElement('script');
	script.src = urlOfTheLibrary;
	script.async = true;
	document.body.appendChild(script);
}


class Cookies extends Component {
	state = {
		aNavbarLinks: [
			{id: 0, linkHref: "/", linkNome: "HOME"},
		],
	}
	render() {
		return (
			<>
				<div className="page">
					{/*SEZIONE BARRA DI NAVIGAZIONE */}
					<Navbar 
						linkSito='https://www.eliosfera.it'
						logo={logo}
						deskLogo='Il logo della casa editrice Eliosfera'
						aNavbarLinks = {this.state.aNavbarLinks}
					/>
					<div id="pagina">
						<Intestazione
							pagina="INFORMATIVA SUI COOKIES"
							backgroundStyle={intestazioneBackgroundStyle}
							titolo="VERSO HEMELSLINN"
							romanzo="un romanzo di"
							autore="MAURIZIO FERUGLIO"
						/>


						<section className="ftco-section bg-dark">
							<div className="container">
								<div className="row justify-content-center">
									<div className="col-md-8 mb-5 heading-section text-center ftco-animate">
										<div className="heading-section text-center mb-5">
											<h2>COOKIES</h2>
											<p>Informativa sui cookies</p>
										</div>
									</div>
								</div>
								<div className="row d-flex">
									
									<h2>Cosa sono i cookie?</h2>
									<p>Sono piccoli messaggi informativi memorizzati dal tuo browser all’interno di un file e salvati sul tuo computer e permettono di riconoscere in automatico il sito visitato.</p>

									<h2>Perché è necessario accettare i cookie per effettuare acquisti nella libreria on-line di Eliosfera Editrice?</h2>
									<p>Sul nostro Store utilizziamo i cookie per tenere in memoria i prodotti inseriti nel carrello. Senza cookie attivati la libreria on-line non sarebbe in grado di mantenere nel carrello i prodotti da te scelti, né riuscirebbe a eseguire la procedura di acquisto. Non avrai, perciò, la possibilità di effettuare acquisti o di usufruire delle funzioni di base del sito. Se vuoi fare acquisti nella nostra Libreria on-line configura il browser in modo che ne accetti i cookie. Per avere informazioni sui dati che raccogliamo e come li utilizziamo, consulta la nostra <a href="/privacy" target="_blank" rel="noreferrer noopener" title="Link all'Informativa sulla Privacy'">Informativa sulla Privacy</a>.</p>

									<h2>I cookie sono pericolosi?</h2>
									<p>No. Sono solo dei minuscoli database. Puoi configurare il tuo browser in modo da eliminarli in automatico quando lo chiudi.</p>

									<h2>Come configurare il browser per abilitare o eliminare i cookie?</h2>
									<p>Clicca sul link del browser che utilizzi, ti rimanderà alla pagina del produttore del browser che spiega come gestire o eliminare i cookie.</p>

									<ul>
										<li>Abilitare o disabilitare i cookie su <a href="http://mzl.la/MnRK4j"  rel="noreferrer noopener" target="_blank">Mozilla Firefox</a></li>
										<li>Eliminare i cookie su <a href="http://mzl.la/Mbeo3e"  rel="noreferrer noopener" target="_blank" title="Link alle informazioni per abilitare o disabilitare i cookie su Firefox">Mozilla Firefox</a></li>
										<li>Abilitare o disabilitare i cookie su <a href="https://support.google.com/chrome/answer/95647?hl=it&amp;ref_topic=14666"  rel="noreferrer noopener" target="_blank" title="Link alle informazioni per abilitare o disabilitare i cookie su Google Chrome">Google chrome</a></li>
										<li>Eliminare i cookie su <a href="http://support.google.com/chrome/bin/answer.py?hl=it&amp;answer=95582&amp;topic=14666&amp;ctx=topic"  rel="noreferrer noopener" target="_blank" title="Link alle informazioni per eliminare i cookie su Firefox">Google Chrome</a></li>
										<li><a href="http://windows.microsoft.com/it-it/internet-explorer/delete-manage-cookies#ie=ie-11"  rel="noreferrer noopener" target="_blank" title="Link alle informazioni per abilitare o disabilitare i cookie su Internet Explorer">Internet Explorer</a>, nella pagina è presente un menu a tendina per selezionare la versione del browser.</li>
										<li><a href="http://help.opera.com/Windows/10.00/it/cookies.html"  rel="noreferrer noopener" target="_blank" title="Link alle informazioni per abilitare o disabilitare i cookie su Opera">Opera</a></li>
										<li>Se usi <strong>Safari</strong> come browser, segui queste istruzioni:
										<ul>
											<li>Clicca su "Safari" &gt; "Preferenze" &gt; Sezione "Sicurezza".</li>
											<li>Clicca su <strong>Dettagli</strong> e potrai eliminare tutti i cookie o scegliere soltanto i siti interessati.</li>
										</ul>
										</li>
										<li>Se usi <strong>Safari su iPad o iPhone</strong>, segui queste istruzioni:
										<ul>
											<li>Tap su "Impostazioni" &gt; "Safari".</li>
											<li>Per accettare i cookie, tap su <strong>Accetta cookie</strong>. Selezionare <strong>Da visitati.</strong></li>
											<li>Per eliminare i cookie, tap su <strong>Cancella cookie e dati</strong>. Tap su <strong>Cancella</strong>.</li>
										</ul>
										</li>
										<li>Se usi il browser di <strong>Android</strong>, segui queste istruzioni:
										<ul>
											<li>Tap su "browser".</li>
											<li>Tap sulle <strong>tre schede</strong> a fianco della casella dell'indirizzo.</li>
											<li>Tap sul menu (i tre quadratini verticali).</li>
											<li>Tap sulla voce <strong>Impostazioni</strong>.</li>
											<li>Tap su <strong>Privacy e sicurezza</strong>.</li>
											<li>Per accettare i cookie seleziona il check <strong>Accetta cookie</strong>.</li>
											<li>Per eliminare i cookie, fai tap sulla voce <strong>Cancella tutti i dati del cookie</strong>.</li>
											<li>Infine tap su <strong>OK</strong>.</li>
										</ul>
										</li>
									</ul>
								</div>
							</div>
						</section>

           
						<Piedone
							piedoneLinkHowToMint=''
							piedoneLinkNFTsProvenance=''
							piedoneLinkEbooksProvenance=''
							piedoneLinkWhitePaper=''
							piedoneLinkTermConditions=''
							piedoneLinkCookies='/cookies'
							piedoneLinkPrivacy='/privacy'
							piedoneLinkSmartContracts=''
							piedoneIframe=''
							piedoneTwitter='https://twitter.com/eliosferaed'
							piedoneInstagram=''
							piedoneFacebook='https://www.facebook.com/eliosfera'
							piedoneDiscord=''
							piedonePinterest=''
							piedoneOpensea=''
							piedoneEliosfera='hhttps://www.eliosfera.it/maurizio-feruglio-verso-hemelslinn-copertina-rigida-libro'
							piedoneYoutube='https://www.youtube.com/@eliosferaeditrice2911'
							piedoneCopyright="Eliosfera Editrice &copy;"
							piedoneCopyrightDisclaimer="Tutti i diritti riservati"
							piedoneCCBY='Questo template è costruito sulla base di <i class="icon-heart" aria-hidden="true"></i> <a href="https://colorlib.com" target="_blank" rel="noreferrer noopener">Colorlib</a>'
						/>




      				</div>
					{AddLibrary('js/jquery.min.js')}
					{AddLibrary('js/jquery-migrate-3.0.1.min.js')}
					{AddLibrary('js/jquery.waypoints.min.js')}
					{AddLibrary('js/jquery.stellar.min.js')}
					{AddLibrary('js/owl.carousel.min.js')}
					{AddLibrary('js/jquery.magnific-popup.min.js')}
					{AddLibrary('js/aos.js')}
					{AddLibrary('js/jquery.animateNumber.min.js')}
					{AddLibrary('js/scrollax.min.js')}
					{AddLibrary('js/jquery.mb.YTPlayer.min.js')}
					{AddLibrary('js/bootstrap-datepicker.js')}
					{AddLibrary('js/jquery.timepicker.min.js')}
					{AddLibrary('js/main.js')}

    			</div>
			
			</>
		);
	}
}

export default Cookies;