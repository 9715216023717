import React, {Component} from 'react';
import Intestazione from './components_novel/intestazione';
import Post from './components_novel/post';
import Ebook from './components/ebook';
import Piedone from './components/piedone';
import Navbar from './components/navbar';

import logo from './images/Logo_Eliosfera-trasparente-scritta_nera-orizzontale-due-righe-v.3.1-70x280.png';

import intestazioneBackgroundImage from './images/verso-hemelslinn-sfondo-2.jpg';


import backgroundPostImage1 from './images/blog/Banner-184-Sirius-book-375x300.jpg';
import backgroundPostImage2 from './images/blog/Banner-171-Nel-crepaccio-book-375x300.jpg';
import backgroundPostImage3 from './images/blog/Banner-162-Non-vuol-dire-nulla-book-375x300.jpg';
import backgroundPostImage4 from './images/blog/Banner-154-Comunicazione-book-375x300.jpg';
import backgroundPostImage5 from './images/blog/Banner-143-Lydja-book-375x300.jpg';
import backgroundPostImage6 from './images/blog/Banner-134-Primo-contatto-book-375x300.jpg';
import backgroundPostImage7 from './images/blog/Banner-092-Lisier-book-375x300.jpg';
import backgroundPostImage8 from './images/blog/Banner-091-Lisier-book-375x300.jpg';
import backgroundPostImage9 from './images/blog/Banner-074-Uno-strano-incontro-book-375x300.jpg';
import backgroundPostImage10 from './images/blog/Banner-065-Attacco-alla-Delphis-375x300.jpg';
import backgroundPostImage11 from './images/blog/Banner-060-Prigionieri-di-Tros-375x300.jpg';
import backgroundPostImage12 from './images/blog/Banner-059-Prigionieri-di-Tros-375x300.jpg';
import backgroundPostImage13 from './images/blog/Banner-045-Ritorno-a-Prixian-375x300.jpg';
import backgroundPostImage14 from './images/blog/Banner-037-Jaleh-braccata-book-375x300.jpg';
import backgroundPostImage15 from './images/blog/Banner-032-033-Nella-Delphis-book-375x300.jpg';
import backgroundPostImage16 from './images/blog/Banner-012-Prixian-book-375x300.jpg';
import backgroundPostImage17 from './images/blog/Banner-010-011-Prixian-book-375x300.jpg';
import backgroundPostImage18 from './images/blog/Banner-009-Preludio-book-375x300.jpg';


import backgroundEbookImage from './images/Banner-verso-hemelslinn-tablet-smartphone.png';


const PostStyle1 = {backgroundImage: `url(${backgroundPostImage1})`,};
const PostStyle2 = {backgroundImage: `url(${backgroundPostImage2})`,};
const PostStyle3 = {backgroundImage: `url(${backgroundPostImage3})`,};
const PostStyle4 = {backgroundImage: `url(${backgroundPostImage4})`,};
const PostStyle5 = {backgroundImage: `url(${backgroundPostImage5})`,};
const PostStyle6 = {backgroundImage: `url(${backgroundPostImage6})`,};
const PostStyle7 = {backgroundImage: `url(${backgroundPostImage7})`,};
const PostStyle8 = {backgroundImage: `url(${backgroundPostImage8})`,};
const PostStyle9 = {backgroundImage: `url(${backgroundPostImage9})`,};
const PostStyle10 = {backgroundImage: `url(${backgroundPostImage10})`,};
const PostStyle11 = {backgroundImage: `url(${backgroundPostImage11})`,};
const PostStyle12 = {backgroundImage: `url(${backgroundPostImage12})`,};
const PostStyle13 = {backgroundImage: `url(${backgroundPostImage13})`,};
const PostStyle14 = {backgroundImage: `url(${backgroundPostImage14})`,};
const PostStyle15 = {backgroundImage: `url(${backgroundPostImage15})`,};
const PostStyle16 = {backgroundImage: `url(${backgroundPostImage16})`,};
const PostStyle17 = {backgroundImage: `url(${backgroundPostImage17})`,};
const PostStyle18 = {backgroundImage: `url(${backgroundPostImage18})`,};



const intestazioneBackgroundStyle = {backgroundImage: `url(${intestazioneBackgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center center', backgroundAttachment: 'fixed', height: '300px'};

const EbookStyle = {backgroundImage: `url(${backgroundEbookImage})`,};

//const Style = {
//  backgroundImage: `url("images/verso-hemelslinn-volume.jpg")`
//};
// Create the function
export function AddLibrary(urlOfTheLibrary) {
	const script = document.createElement('script');
	script.src = urlOfTheLibrary;
	script.async = true;
	document.body.appendChild(script);
}


class Sommario extends Component {
	state = {
		blogPost: [
			{id: 0, stilePost: PostStyle1, titoloPost: "Sirius", deskLinkBlog: "Link to the Sirius preview", dataPost: "18 october 2021", autorePost: "Eliosfera Editrice", linkPost: "https://www.eliosfera.it/blog-sirius-anteprima-toward-hemelslinn-feruglio", descrizionePost: "Today we want to let you read, in preview, the page with the <strong>Sirius</strong> theme."},
			{id: 1, stilePost: PostStyle2, titoloPost: "Crepaccio", deskLinkBlog: "Link to the Crepaccio preview", dataPost: "05 october 2021", autorePost: "Eliosfera Editrice", linkPost: "https://www.eliosfera.it/blog-crepaccio-anteprima-toward-hemelslinn-feruglio", descrizionePost: "Today we want to let you read a preview of the page with the <strong>Crepaccio</strong> theme."},
			{id: 2, stilePost: PostStyle3, titoloPost: "Non vuol dire nulla", deskLinkBlog: "Link to the Sirius preview", dataPost: "14 october 2021", autorePost: "Eliosfera Editrice", linkPost: "https://www.eliosfera.it/blog-non-vuol-dire-nulla-anteprima-toward-hemelslinn-feruglio", descrizionePost: "Today we want to let you read a preview of the page with the <strong>Non vuol dire nulla</strong> theme."},
			{id: 3, stilePost: PostStyle4, titoloPost: "Comunicare", deskLinkBlog: "Link to the Comunicare preview", dataPost: "12 settembre 2021", autorePost: "Eliosfera Editrice", linkPost: "https://www.eliosfera.it/blog-comunicare-anteprima-toward-hemelslinn-feruglio", descrizionePost: "Today we want to let you read, in preview, the page with the <strong>Comunicare</strong> theme."},
			{id: 4, stilePost: PostStyle5, titoloPost: "Lýdja", deskLinkBlog: "Link to the Lýdja preview", dataPost: "11 settembre 2021", autorePost: "Eliosfera Editrice", linkPost: "https://www.eliosfera.it/blog-lidia-anteprima-toward-hemelslinn-feruglio", descrizionePost: "Today we want to let you read, in preview, the page with the <strong>Lýdja</strong> theme."},
			{id: 5, stilePost: PostStyle6, titoloPost: "Primo contatto", deskLinkBlog: "Link to the Primo contatto preview", dataPost: "18 october 2021", autorePost: "Eliosfera Editrice", linkPost: "https://www.eliosfera.it/blog-primo-contatto-anteprima-toward-hemelslinn-feruglio", descrizionePost: "Today we want to let you read, in preview, the page with the <strong>Primo contatto</strong> theme."},
			{id: 6, stilePost: PostStyle7, titoloPost: "Lisier, parte seconda", deskLinkBlog: "Link to the Lisier, parte seconda preview", dataPost: "07 september 2021", autorePost: "Eliosfera Editrice", linkPost: "https://www.eliosfera.it/blog-Lisier-2-anteprima-toward-hemelslinn-feruglio", descrizionePost: "Today we want to let you read, in preview, the page with the <strong>Lisier, parte seconda</strong> theme."},
			{id: 7, stilePost: PostStyle8, titoloPost: "Lisier, parte prima", deskLinkBlog: "Link to the Lisier, parte prima preview", dataPost: "05 september 2021", autorePost: "Eliosfera Editrice", linkPost: "https://www.eliosfera.it/blog-Lisier-1-anteprima-toward-hemelslinn-feruglio", descrizionePost: "Today we want to let you read, in preview, the page with the <strong>Lisier, parte prima</strong> theme."},
			{id: 8, stilePost: PostStyle9, titoloPost: "Uno strano incontro", deskLinkBlog: "Link to the Uno strano incontro preview", dataPost: "28 august 2021", autorePost: "Eliosfera Editrice", linkPost: "https://www.eliosfera.it/blog-strano-incontro-anteprima-toward-hemelslinn-feruglio", descrizionePost: "Today we want to let you read, in preview, the page with the <strong>Uno strano incontro</strong> theme."},
			{id: 9, stilePost: PostStyle10, titoloPost: "Attacco alla Delphìs", deskLinkBlog: "Link to the Attacco alla Delphìs preview", dataPost: "02 september 2021", autorePost: "Eliosfera Editrice", linkPost: "https://www.eliosfera.it/blog-attacco-delphis-anteprima-toward-hemelslinn-feruglio", descrizionePost: "Today we want to let you read, in preview, the page with the <strong>Attacco alla Delphìs</strong> theme."},
			{id: 10, stilePost: PostStyle11, titoloPost: "Prigionieri di Tros, parte seconda", deskLinkBlog: "Link to the Prigionieri di Tros, parte seconda preview", dataPost: "31 august 2021", autorePost: "Eliosfera Editrice", linkPost: "https://www.eliosfera.it/blog-Prigionieri-Tros-2-toward-hemelslinn-feruglio", descrizionePost: "Today we want to let you read, in preview, the page with the <strong>Prigionieri di Tros, parte seconda</strong> theme."},
			{id: 11, stilePost: PostStyle12, titoloPost: "Prigionieri di Tros, parte prima", deskLinkBlog: "Link to the Prigionieri di Tros, parte prima preview", dataPost: "29 august 2021", autorePost: "Eliosfera Editrice", linkPost: "https://www.eliosfera.it/blog-Prigionieri-Tros--anteprima-toward-hemelslinn-feruglio", descrizionePost: "Today we want to let you read, in preview, the page with the <strong>Prigionieri di Tros, parte prima</strong> theme."},
			{id: 12, stilePost: PostStyle13, titoloPost: "Ritorno a Prixian", deskLinkBlog: "Link to the Ritorno a Prixian preview", dataPost: "28 august 2021", autorePost: "Eliosfera Editrice", linkPost: "https://www.eliosfera.it/blog-ritorno-prixian-anteprima-toward-hemelslinn-feruglio", descrizionePost: "Today we want to let you read, in preview, the page with the <strong>Ritorno a Prixian</strong> theme."},
			{id: 13, stilePost: PostStyle14, titoloPost: "Jàleh braccata", deskLinkBlog: "Link to the Jàleh braccata preview", dataPost: "26 august 2021", autorePost: "Eliosfera Editrice", linkPost: "https://www.eliosfera.it/blog-jaleh-braccata-anteprima-toward-hemelslinn-maurizio-feruglio", descrizionePost: "Today we want to let you read, in preview, the page with the <strong>Jàleh braccata</strong> theme."},
			{id: 14, stilePost: PostStyle15, titoloPost: "Nella Delphìs", deskLinkBlog: "Link to the Nella Delphìs preview", dataPost: "24 august 2021", autorePost: "Eliosfera Editrice", linkPost: "https://www.eliosfera.it/blog-nella-delpis-anteprima-toward-hemelslinn-maurizio-feruglio", descrizionePost: "Today we want to let you read, in preview, the page with the <strong>Nella Delphìs</strong> theme."},
			{id: 15, stilePost: PostStyle16, titoloPost: "Prixian, parte seconda", deskLinkBlog: "Link to the Prixian, parte seconda preview", dataPost: "22 august 2021", autorePost: "Eliosfera Editrice", linkPost: "https://www.eliosfera.it/blog-prixian-2-anteprima-toward-hemelslinn-maurizio-feruglio", descrizionePost: "Today we want to let you read, in preview, the page with the <strong>Prixian, parte seconda</strong> theme."},
			{id: 16, stilePost: PostStyle17, titoloPost: "Prixian, parte prima", deskLinkBlog: "Link to the Prixian, parte prima preview", dataPost: "21 august 2021", autorePost: "Eliosfera Editrice", linkPost: "https://www.eliosfera.it/blog-prixian-1-anteprima-toward-hemelslinn-maurizio-feruglio", descrizionePost: "Today we want to let you read, in preview, the page with the <strong>Prixian, parte prima</strong> theme."},
			{id: 17, stilePost: PostStyle18, titoloPost: "Preludio", deskLinkBlog: "Link to the Preludio preview", dataPost: "21 august 2021", autorePost: "Eliosfera Editrice", linkPost: "https://www.eliosfera.it/blog-preludio-anteprima-toward-hemelslinn-maurizio-feruglio", descrizionePost: "Today we want to let you read, in preview, the page with the <strong>Preludio</strong> theme."},
		],
		aNavbarLinks: [
			{id: 0, linkHref: "https://towardhemelslinn.io/", linkNome: "HOME NFT"},
			{id: 1, linkHref: "/", linkNome: "NOVEL"},
		],
	}


	render() {
		return (
			<>
				<div className="page">
				    <Navbar 
						linkSito='https://www.eliosfera.it'
						logo={logo}
						deskLogo='The Eliosfera publishing house logo'
						aNavbarLinks = {this.state.aNavbarLinks}
					/>				
					<div id="pagina">

						<Intestazione
							pagina="BLOG"
							backgroundStyle={intestazioneBackgroundStyle}
							titolo="TOWARD HEMELSLINN"
							romanzo="a novel by"
							autore="MAURIZIO FERUGLIO"
						/>


						<section className="ftco-section">
							<div className="container">
								<div className="row justify-content-center">
									<div className="col-md-8 mb-5 heading-section text-center ftco-animate">
										<div className="heading-section text-center mb-5">
											<h2>BLOG</h2>
											<p>All articles, events and news of the blog</p>
										</div>
									</div>
								</div>
								<div className="row d-flex">
									{this.state.blogPost.map(blogpost => (
										<Post
											key={blogpost.id}
											stilePost={blogpost.stilePost}
											titoloPost={blogpost.titoloPost}
											deskLinkBlog={blogpost.deskLinkBlog}
											dataPost={blogpost.dataPost}
											autorePost={blogpost.autorePost}
											linkPost={blogpost.linkPost}
											buttonText="Read more…"
											descrizionePost={blogpost.descrizionePost}
										/>
									))}
								</div>
							</div>
						</section>


						<Ebook
							ebookStile={EbookStyle}
							ebookTitle="TOWARD HEMELSLINN ebook"
							ebookTagLine="Read it also in the ebook on all devices!"
							ebookElenco='<li><span class="ion-ios-checkmark-circle mr-2"></span> <em>Accessible</em> for the blind and visually impaired, in epub3 format.</li>
							<li><span class="ion-ios-checkmark-circle mr-2"></span> Other formats available: Kindle and PDF.</li>
							<li><span class="ion-ios-checkmark-circle mr-2"></span> You can read it with a screen reader or a braille reader.</li>
							<li><span class="ion-ios-checkmark-circle mr-2"></span> You can read it with Kindle eReaders, Kobo and others.</li>
							<li><span class="ion-ios-checkmark-circle mr-2"></span> You can read it on a tablet, smartphone or PC.</li>'
							ebookInfoAccessibilita="To learn more about accessibility, read (in italian language):"
							ebookLinkAccessibilita="https://www.eliosfera.it/ebook-con-contenuti-accessibili-eliosfera-editrice"
							ebookTitleLink="Link to the article Ebook with accessible content."
							ebookDescAccessibilita="Ebook with accessible content"
							ebookCallAction="So what are you waiting for! Adventure awaits you digitally too!"
							ebookLink="https://www.eliosfera.it/newsletter-eliosfera-editrice"
							ebookButton="SUBSCRIBE"
						/>


						<Piedone
							piedoneLinkHowToMint=''
							piedoneLinkProvenance=''
							piedoneLinkWhitePaper=''
							piedoneLinkTermConditions=''
							piedoneLinkCookies='/cookies'
							piedoneLinkPrivacy='/privacy'
							piedoneLinkSmartContracts='https://towardhemelslinn.io/smartcontracts'	
							piedoneIframe=''
							piedoneTwitter='https://twitter.com/eliosferaed'
							piedoneInstagram=''
							piedoneFacebook='https://www.facebook.com/eliosfera'
							piedoneDiscord=''
							piedonePinterest=''
							piedoneOpensea=''
							piedoneEliosfera='hhttps://www.eliosfera.it/maurizio-feruglio-verso-hemelslinn-copertina-rigida-libro'
							piedoneYoutube='https://www.youtube.com/@eliosferaeditrice2911'
							piedoneCopyright="Eliosfera Editrice &copy;"
							piedoneCopyrightDisclaimer="Tutti i diritti riservati"
							piedoneCCBY='Questo template è costruito sulla base di  <i class="icon-heart" aria-hidden="true"></i> <a href="https://colorlib.com" target="_blank" rel="noreferrer noopener">Colorlib</a>'
						/>

					</div>

					{AddLibrary(
						'js/aos.js')}
					{AddLibrary(
						'js/jquery.animateNumber.min.js')}
					{AddLibrary(
						'js/jquery.mb.YTPlayer.min.js')}
					{AddLibrary(
						'js/jquery.min.js')}
					{AddLibrary(
						'js/jquery.stellar.min.js')}
					{AddLibrary(
						'js/jquery.waypoints.min.js')}
					{AddLibrary(
						'js/jquery-migrate-3.0.1.min.js')}
					{AddLibrary(
						'js/owl.carousel.min.js')}
					{AddLibrary(
						'js/scrollax.min.js')}
					{AddLibrary(
						'js/main.js')}
				</div>
			</>
		);
	}
}

export default Sommario;