import React from 'react';
import ReactDOM from 'react-dom';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fas as far } from '@fortawesome/free-solid-svg-icons'
import { faFontAwesome } from '@fortawesome/free-brands-svg-icons'

import './index.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import App from './App';
import Summary from './summary';
import Blog from './blog';
import Privacy from './privacy';
import Cookies from './cookies';

import reportWebVitals from './reportWebVitals';

import './css/animate.css';
import './css/ionicons.min.css';
import './css/owl.carousel.min.css';
//import './css/modal-video.scss';
import "./css/icomoon.css";
import "./css/open-iconic-bootstrap.min.css";
import "./css/owl.theme.default.min.css";
import "./css/magnific-popup.css";
import "./css/bootstrap-datepicker.css";
import "./css/jquery.timepicker.css";
import "./css/flaticon.css";
import './css/style.css';

library.add(fas, far, faFontAwesome)

const routing = (
	<BrowserRouter>
		<Routes>
			<Route exact path="/" element={<App/>} />
			<Route path="/blog" element={<Blog/>} />
			<Route path="/summary" element={<Summary/>} />
			<Route path="/privacy" element={<Privacy/>} />
			<Route path="/cookies" element={<Cookies/>} />
		</Routes>
	</BrowserRouter>
)

ReactDOM.render(routing, document.getElementById('root'));
/*ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
); */

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
