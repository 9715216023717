import React, {Component} from 'react';
import Intestazione from './components_novel/intestazione';
import Capitolo from './components/capitolo';
import Ebook from './components/ebook';
import Piedone from './components/piedone';
import Navbar from './components/navbar';

import logo from './images/Logo_Eliosfera-trasparente-scritta_nera-orizzontale-due-righe-v.3.1-70x280.png';

import intestazioneBackgroundImage from './images/verso-hemelslinn-sfondo-2.jpg';

import backgroundCapitoloImage1 from './images/sommario/verso-hemelslinn-preludio.jpg';
import backgroundCapitoloImage2 from './images/sommario/verso-hemelslinn-prixian.jpg';
import backgroundCapitoloImage3 from './images/sommario/verso-hemelslinn-in-viaggio-per-il-futuro.jpg';
import backgroundCapitoloImage4 from './images/sommario/verso-hemelslinn-inseguimento.jpg';
import backgroundCapitoloImage5 from './images/sommario/verso-hemelslinn-uno-strano-incontro.jpg';
import backgroundCapitoloImage6 from './images/sommario/verso-hemelslinn-lisier.jpg';
import backgroundCapitoloImage7 from './images/sommario/verso-hemelslinn-naufraghi.jpg';
import backgroundCapitoloImage8 from './images/sommario/verso-hemelslinn-primo-contatto.jpg';
import backgroundCapitoloImage9 from './images/sommario/verso-hemelslinn-il-continente-di-ghiaccio.jpg';
import backgroundCapitoloImage10 from './images/sommario/verso-hemelslinn-la-battaglia-sulla-neve.jpg';
import backgroundCapitoloImage11 from './images/sommario/verso-hemelslinn-hemelslinn.jpg';
import backgroundCapitoloImage12 from './images/sommario/verso-hemelslinn-missione-amore.jpg';
import backgroundCapitoloImage13 from './images/sommario/verso-hemelslinn-scoperta.jpg';
import backgroundCapitoloImage14 from './images/sommario/verso-hemelslinn-societa-del-vecchio-mondo.jpg';
import backgroundCapitoloImage15 from './images/sommario/verso-hemelslinn-il-dilemma-del-gorilla.jpg';
import backgroundCapitoloImage16 from './images/sommario/verso-hemelslinn-lunga-notte.jpg';
import backgroundCapitoloImage17 from './images/sommario/verso-hemelslinn-caput-mundi.jpg';
import backgroundCapitoloImage18 from './images/sommario/verso-hemelslinn-tornare-o-non-tornare.jpg';
import backgroundCapitoloImage19 from './images/sommario/verso-hemelslinn-viaggio-verso-il-futuro.jpg';
import backgroundCapitoloImage20 from './images/sommario/verso-hemelslinn-ritrovato-un-vecchio-amico.jpg';
import backgroundCapitoloImage21 from './images/sommario/verso-hemelslinn-salvataggio.jpg';
import backgroundCapitoloImage22 from './images/sommario/verso-hemelslinn-uno-due-tanti.jpg';
import backgroundCapitoloImage23 from './images/sommario/verso-hemelslinn-amore-e-pericolo.jpg';
import backgroundCapitoloImage24 from './images/sommario/verso-hemelslinn-un-nuovo-arrivo.jpg';
import backgroundCapitoloImage25 from './images/sommario/verso-hemelslinn-rivoluzione.jpg';
import backgroundCapitoloImage26 from './images/sommario/verso-hemelslinn-ritorno.jpg';
import backgroundCapitoloImage27 from './images/sommario/verso-hemelslinn-una-ferita-risanata.jpg';
import backgroundCapitoloImage28 from './images/sommario/verso-hemelslinn-epilogo-essenza.jpg';
import backgroundEbookImage from './images/Banner-verso-hemelslinn-tablet-smartphone.png';


const CapitoloStyle1 = {backgroundImage: `url(${backgroundCapitoloImage1})`,};
const CapitoloStyle2 = {backgroundImage: `url(${backgroundCapitoloImage2})`,};
const CapitoloStyle3 = {backgroundImage: `url(${backgroundCapitoloImage3})`,};
const CapitoloStyle4 = {backgroundImage: `url(${backgroundCapitoloImage4})`,};
const CapitoloStyle5 = {backgroundImage: `url(${backgroundCapitoloImage5})`,};
const CapitoloStyle6 = {backgroundImage: `url(${backgroundCapitoloImage6})`,};
const CapitoloStyle7 = {backgroundImage: `url(${backgroundCapitoloImage7})`,};
const CapitoloStyle8 = {backgroundImage: `url(${backgroundCapitoloImage8})`,};
const CapitoloStyle9 = {backgroundImage: `url(${backgroundCapitoloImage9})`,};
const CapitoloStyle10 = {backgroundImage: `url(${backgroundCapitoloImage10})`,};
const CapitoloStyle11 = {backgroundImage: `url(${backgroundCapitoloImage11})`,};
const CapitoloStyle12 = {backgroundImage: `url(${backgroundCapitoloImage12})`,};
const CapitoloStyle13 = {backgroundImage: `url(${backgroundCapitoloImage13})`,};
const CapitoloStyle14 = {backgroundImage: `url(${backgroundCapitoloImage14})`,};
const CapitoloStyle15 = {backgroundImage: `url(${backgroundCapitoloImage15})`,};
const CapitoloStyle16 = {backgroundImage: `url(${backgroundCapitoloImage16})`,};
const CapitoloStyle17 = {backgroundImage: `url(${backgroundCapitoloImage17})`,};
const CapitoloStyle18 = {backgroundImage: `url(${backgroundCapitoloImage18})`,};
const CapitoloStyle19 = {backgroundImage: `url(${backgroundCapitoloImage19})`,};
const CapitoloStyle20 = {backgroundImage: `url(${backgroundCapitoloImage20})`,};
const CapitoloStyle21 = {backgroundImage: `url(${backgroundCapitoloImage21})`,};
const CapitoloStyle22 = {backgroundImage: `url(${backgroundCapitoloImage22})`,};
const CapitoloStyle23 = {backgroundImage: `url(${backgroundCapitoloImage23})`,};
const CapitoloStyle24 = {backgroundImage: `url(${backgroundCapitoloImage24})`,};
const CapitoloStyle25 = {backgroundImage: `url(${backgroundCapitoloImage25})`,};
const CapitoloStyle26 = {backgroundImage: `url(${backgroundCapitoloImage26})`,};
const CapitoloStyle27 = {backgroundImage: `url(${backgroundCapitoloImage27})`,};
const CapitoloStyle28 = {backgroundImage: `url(${backgroundCapitoloImage28})`,};

const intestazioneBackgroundStyle = {backgroundImage: `url(${intestazioneBackgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center center', backgroundAttachment: 'fixed', height: '300px'};

const EbookStyle = {backgroundImage: `url(${backgroundEbookImage})`,};

// Create the function
export function AddLibrary(urlOfTheLibrary) {
  const script = document.createElement('script');
  script.src = urlOfTheLibrary;
  script.async = true;
  document.body.appendChild(script);
}


class Sommario extends Component {
  state = {
    Capitoli: [
		{id: 0, capitoloStile: CapitoloStyle1, capitoloTitolo: "Preludio", capitoloDescrizione: "L'incipit. L'esplosione di una stella, il fulcro della storia."},
		{id: 1, capitoloStile: CapitoloStyle2, capitoloTitolo: "Prixian", capitoloDescrizione: "Alla scoperta dell'ambiente, della cultura e della società in cui vive l'equipaggio della Delphís."},
		{id: 2, capitoloStile: CapitoloStyle3, capitoloTitolo: "In viaggio per il futuro", capitoloDescrizione: "L'inizio del viaggio. Fuga dagli inseguitori, nuovi incontri e arrivo alla prima destinazione."},
		{id: 3, capitoloStile: CapitoloStyle4, capitoloTitolo: "L'inseguimento", capitoloDescrizione: "L'equipaggio della Delphìs, braccato, deve rifornirsi al più presto."},
		{id: 4, capitoloStile: CapitoloStyle5, capitoloTitolo: "Uno strano incontro", capitoloDescrizione: "Un protagonista entra in gioco aiutandoli a fuggire."},
		{id: 5, capitoloStile: CapitoloStyle6, capitoloTitolo: "Lisier", capitoloDescrizione: " faccia a faccia con una stella, in corsa contro il tempo."},
		{id: 6, capitoloStile: CapitoloStyle7, capitoloTitolo: "Naufraghi", capitoloDescrizione: "La quiete e la fulgida luce dominavano il mare di tranquillità. Un vascello vi navigava senza meta."},
		{id: 7, capitoloStile: CapitoloStyle8, capitoloTitolo: "Primo contatto", capitoloDescrizione: "L'equipaggio della Delphìs atterra su un pianeta sconosciuto."},
		{id: 8, capitoloStile: CapitoloStyle9, capitoloTitolo: "Il continente di ghiaccio", capitoloDescrizione: "Viaggio nella terra dei ghiacci."},
		{id: 9, capitoloStile: CapitoloStyle10, capitoloTitolo: "La battaglia sulla neve", capitoloDescrizione: "La battaglia che accenderà i sentimenti."},
		{id: 10, capitoloStile: CapitoloStyle11, capitoloTitolo: "Hemelslinn", capitoloDescrizione: "La conoscenza di un'antica cultura."},
		{id: 11, capitoloStile: CapitoloStyle12, capitoloTitolo: "La missione d'amore", capitoloDescrizione: "Il coronamento di nuovi amori."},
		{id: 12, capitoloStile: CapitoloStyle13, capitoloTitolo: "La scoperta", capitoloDescrizione: "Una scoperta che cambierà il paradigma."},
		{id: 13, capitoloStile: CapitoloStyle14, capitoloTitolo: "La società del vecchio mondo", capitoloDescrizione: "Alla scoperta di Hemelslinn."},
		{id: 14, capitoloStile: CapitoloStyle15, capitoloTitolo: "Il dilemma del gorilla", capitoloDescrizione: "Un personaggio è combattuto fra la lealtà ai suoi compagni e il desiderio di salvare la sua amata."},
		{id: 15, capitoloStile: CapitoloStyle16, capitoloTitolo: "La lunga notte", capitoloDescrizione: "La coesione del gruppo rinsaldata nella lunga notte."},
		{id: 16, capitoloStile: CapitoloStyle17, capitoloTitolo: "Caput Mundi", capitoloDescrizione: "Evidenza di un mondo ormai alla rovina."},
		{id: 17, capitoloStile: CapitoloStyle18, capitoloTitolo: "Tornare o non tornare", capitoloDescrizione: "Il conflitto interiore e fra i protagonisti in procinto di separarsi."},
		{id: 18, capitoloStile: CapitoloStyle19, capitoloTitolo: "Viaggio verso il futuro", capitoloDescrizione: "Alcuni dei personaggi riescono a tornare. Cercheranno di completare la missione 2."},
		{id: 19, capitoloStile: CapitoloStyle20, capitoloTitolo: "Ritrovato un vecchio amico", capitoloDescrizione: "Un enigmatico personaggio entra in scena."},
		{id: 20, capitoloStile: CapitoloStyle21, capitoloTitolo: "Salvataggio", capitoloDescrizione: "Il salvataggio dagli sgherri della Orion Galactic di una persona amata."},
		{id: 21, capitoloStile: CapitoloStyle22, capitoloTitolo: "Uno, due, tanti", capitoloDescrizione: "Iniziano i preparativi. Inseguimento e morte dell'antagonista principale."},
		{id: 22, capitoloStile: CapitoloStyle23, capitoloTitolo: "Amore e pericolo", capitoloDescrizione: "Un incubo che si trasforma nel più bel periodo della vita."},
		{id: 23, capitoloStile: CapitoloStyle24, capitoloTitolo: "Un nuovo arrivo", capitoloDescrizione: "La nascita che allieta."},
		{id: 24, capitoloStile: CapitoloStyle25, capitoloTitolo: "La rivoluzione", capitoloDescrizione: "Completati i preparativi. La quiete della galassia viene stravolta."},
		{id: 25, capitoloStile: CapitoloStyle26, capitoloTitolo: "Hemelslinn, il ritorno", capitoloDescrizione: "Un'amara sorpresa."},
		{id: 26, capitoloStile: CapitoloStyle27, capitoloTitolo: "Una ferita risanata", capitoloDescrizione: "Un protagonista ha il cuore turbato. Non è riuscito a completare la missione."},
		{id: 27, capitoloStile: CapitoloStyle28, capitoloTitolo: "Epilogo, l'essenza", capitoloDescrizione: "La quiete in attesa di una nuova avventura."}
    ],
	aNavbarLinks: [
		{id: 0, linkHref: "/", linkNome: "ROMANZO"},
	],
  }



  render() {
    return (
      <>
        <div className="page">
		    <Navbar 
				linkSito='https://www.eliosfera.it'
				logo={logo}
				deskLogo='Il logo della casa editrice Eliosfera'
				aNavbarLinks = {this.state.aNavbarLinks}
			/>	
          <div id="pagina">
            <Intestazione
              pagina="SOMMARIO"
              backgroundStyle={intestazioneBackgroundStyle}
              titolo="VERSO HEMELSLINN"
              romanzo="un romanzo di"
              autore="MAURIZIO FERUGLIO"
            />

			<section className="ftco-section ftco-no-pb">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-md-10 ftco-animate">
							<div className="heading-section text-center mb-5">
							<h2>SOMMARIO</h2>
							<p>Scorri i capitoli di Verso Hemelslinn e scopri l'avventura!</p>
							</div>
						</div>
					</div>
				</div>
				<div className="container-fluid px-4">
					<div className="row d-flex">
						{this.state.Capitoli.map(capitolo => (
						<Capitolo
							key={capitolo.id}
							sectioncapitolo={capitolo} />
						))}
						
					</div>
				</div>
			</section>


            <Ebook
              ebookStile={EbookStyle}
              ebookTitle="VERSO HEMELSLINN ebook ePub"
              ebookTagLine="Leggilo anche in ebook su tutti i dispositivi!"
              ebookElenco='<li><span class="ion-ios-checkmark-circle mr-2"></span> <em>Accessibile</em> per non vedenti e ipovedenti, in formato epub3.</li>
              <li><span class="ion-ios-checkmark-circle mr-2"></span> Altri formati disponibili: Kindle, Mobi e PDF.</li>
              <li><span class="ion-ios-checkmark-circle mr-2"></span> YPuoi leggerlo con uno screen reader o un lettore braille.</li>
              <li><span class="ion-ios-checkmark-circle mr-2"></span> Puoi leggerlo con Kindle, Kobo e altri eReader</li>
              <li><span class="ion-ios-checkmark-circle mr-2"></span> Puoi leggerlo su tablet, smartphone o PC.</li>'
              ebookInfoAccessibilita="Per saperne di più sull'accessibilità, leggi:"
              ebookLinkAccessibilita="https://www.eliosfera.it/ebook-con-contenuti-accessibili-eliosfera-editrice"
              ebookTitleLink="Link all'articolo Ebook con contenuti accessibili."
              ebookDescAccessibilita="Ebook con contenuti accessibili"
              ebookCallAction="Allora, cosa stai aspettando? L'avventura ti aspetta anche in digitale!"
              ebookLink="https://bit.ly/3upLxps"
              ebookButton="ACQUISTA L'EBOOK"
            />


            <Piedone
				piedoneLinkHowToMint=''
				piedoneLinkProvenance=''
				piedoneLinkWhitePaper=''
				piedoneLinkTermConditions=''
				piedoneLinkCookies='/cookies'
				piedoneLinkPrivacy='/privacy'
				piedoneLinkSmartContracts=''
				piedoneIframe=''
				piedoneTwitter='https://twitter.com/eliosferaed'
				piedoneInstagram=''
				piedoneFacebook='https://www.facebook.com/eliosfera'
				piedoneDiscord=''
				piedonePinterest=''
				piedoneOpensea=''
				piedoneEliosfera='https://www.eliosfera.it/maurizio-feruglio-verso-hemelslinn-copertina-rigida-libro'
				piedoneYoutube='https://www.youtube.com/@eliosferaeditrice2911'
				piedoneCopyright="Eliosfera Editrice &copy;"
				piedoneCopyrightDisclaimer="Tutti i diritti riservati"
				piedoneCCBY='Template basato su <i className="icon-heart" aria-hidden="true"></i> <a href="https://colorlib.com" target="_blank" rel="noreferrer noopener">Colorlib</a>'
            />


          </div>

          {AddLibrary(
            'js/aos.js')}
          {AddLibrary(
            'js/jquery.animateNumber.min.js')}
          {AddLibrary(
            'js/jquery.mb.YTPlayer.min.js')}
          {AddLibrary(
            'js/jquery.min.js')}
          {AddLibrary(
            'js/jquery.stellar.min.js')}
          {AddLibrary(
            'js/jquery.waypoints.min.js')}
          {AddLibrary(
            'js/jquery-migrate-3.0.1.min.js')}
          {AddLibrary(
            'js/owl.carousel.min.js')}
          {AddLibrary(
            'js/scrollax.min.js')}
          {AddLibrary(
            'js/main.js')}
        </div>
      </>
    );
  }
}

export default Sommario;