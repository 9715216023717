import React, {Component} from 'react';

class VideoBackground extends Component {
	state = {
		loading: true
	  };

	  componentDidMount() {
		if (this.video) {
		  this.video.addEventListener("loadeddata", () => {
			this.setState({ loading: false });
		  });
		}
	  }
	
		componentWillUnmount() {
		if (this.video) {
			this.video.removeEventListener("loadeddata", () => {
			});
		}
	}
	render() {

		return (

			<section id="videobackground" className="video-hero js-fullheight" style={this.props.backgroundStyle}>
				<video
					id='video'
					ref={ref => (this.video = ref)}
					autoPlay
					muted
					loop
					style={{
						zIndex: "-1",
						width: "100%",
						left: 0,
						top: 0,
						opacity: this.state.loading ? 0 : 1,
						transition: "opacity, 4s ease-in-out"
					}}
				>
					<source src={this.props.backgroundVideo} type="video/mp4" />
				</video>

                <div className="container">
                    <div className=" overlay-desc js-fullheight justify-content-center d-flex align-items-center">
                        <div className="col-md-8">
                            <div className="text text-center">
                                <h1 dangerouslySetInnerHTML={{ __html: this.props.titolo }}></h1>
                                <br /><span className="subheading">{this.props.romanzo}</span>
                                <h3 className="mb-0">{this.props.autore}</h3>
                                <span className="subheading2">{this.props.tagLine}</span><br />
                                <span className="subheading2">{this.props.tagLine2}</span>
                                {this.props.buttonLink ? (
                                    <>	<br />
                                        <p><a href={this.props.buttonLink} className="btn btn-info py-3 px-4" target="_blank" rel="noreferrer noopener">{this.props.buttonPrenota}</a></p>
                                    </>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

			);
		}
  }
export default VideoBackground;