import React, {Component} from 'react';

class sectionCapitolo extends Component {
    render() {
    return (
        <div className="col-md-6 col-lg-3 ftco-animate">
            <div className="model-entry">
                <div className="model-img" style={this.props.sectioncapitolo.capitoloStile}>
                    <div className="name text-center">
                        <h3>{this.props.sectioncapitolo.capitoloTitolo}</h3>
                    </div>
                    <div className="text text-center">
                        <h3><br /><span>{this.props.sectioncapitolo.capitoloTitolo}</span></h3>
                        <div className="d-flex models-info">
                            <div className="box">
                                <span>{this.props.sectioncapitolo.capitoloDescrizione}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
    }
}

export default sectionCapitolo;